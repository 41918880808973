import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEn from "./locales/en/translation.json";
import translationEs from "./locales/es/translation.json";
import translationZh from "./locales/zh/translation.json";
import translationVi from "./locales/vi/translation.json";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import store from "../redux/store";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: translationEn,
  },
  es: {
    translation: translationEs,
  },
  zh: {
    translation: translationZh,
  },
  vi: {
    translation: translationVi,
  },
};

const state = store.getState();

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  //.use(HttpApi)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init(
    {
      resources,
      // react: {
      //   wait: true,
      //   useSuspense: false,
      // },
      lng: state.language.type,
      fallbackLng: "en",
      debug: false,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      // backend: {
      //   loadPath: "/locales/{{lng}}/{{ns}}.json",
      // },
    },
    (err, t) => {
      console.log(err);
      // i18n.t("Landing.TypeQuePH"); // key in moduleA namespace (defined default)
      // i18n.t("category:key1"); // key in common namespace
    }
  );

export default i18n;
