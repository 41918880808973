import React, { Component } from "react";
import "./style.css";

export default class ComingSoon extends Component {
  render() {
    return (
      <div className="coming-soon">
        <div className="text">Coming soon...</div>
      </div>
    );
  }
}
