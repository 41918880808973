import React from "react";
import ReactPlayer from "react-player";
import "./style.css";

interface Props {
  play: boolean;
  url: string;
}
interface State {
  isPlaying: boolean;
}

class YouTubePlayer extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { isPlaying: false };
  }

  componentDidUpdate() {
    const { isPlaying } = this.state;
    const { play } = this.props;
    if (isPlaying !== play) {
      //   if (play) this.videoRef.current.play();
      //   else this.videoRef.current.pause();
      this.setState({ isPlaying: play });
    }
  }

  componentDidMount() {
    const { play } = this.props;
    // if (play) this.videoRef.current.play();
    //   else this.videoRef.current.pause();
    this.setState({ isPlaying: play });
  }

  onClick = () => {
    const { isPlaying } = this.state;
    // if (isPlaying) {
    //   this.videoRef.current.pause();
    // } else {
    //   this.videoRef.current.play();
    // }
    this.setState({ isPlaying: !isPlaying });
  };

  videoRef = React.createRef<any>();

  render() {
    const { url, play } = this.props;
    const { isPlaying } = this.state;
    if (isPlaying !== play) {
      this.setState({ isPlaying: play });
    }
    const urlWithOutControl = url;
    // if (play)
    //   this.videoRef && this.videoRef.current && this.videoRef.current.play();
    // else
    //   this.videoRef && this.videoRef.current && this.videoRef.current.pause();
    return (
      <div className="youtube-video-wrapper">
        <div>
          <ReactPlayer
            url={url}
            muted={!isPlaying}
            playing={isPlaying}
            width={"100%"}
            className="video-player"
            onClick={this.onClick}
            loop
            controls={false}
            ref={this.videoRef}
          ></ReactPlayer>
        </div>
        <div className="youtube-overlay"></div>
      </div>
    );
  }
}

export default YouTubePlayer;
