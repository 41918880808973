import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { APIUrl } from "Config/ApiConfig";
import _ from "lodash";
import { Path, MaxFileSize } from "../../../../constants";
import Axios from "axios";
import { toast } from "utils/toast";
import { getLanguageString } from "utils/common";
import { endPoint } from "constants/ApiPoints";
import { withTranslation, WithTranslation } from "react-i18next";

interface Props extends WithTranslation {
  openModal?: boolean;
  closeCallBack?: any;
  practiceAreas: any;
}
interface State {
  question: string;
  selectedPracticeArea: any;
  language: string;
  answer: any;
}

class AddQueAnsModal extends React.Component<
  Props & RouteComponentProps<{}>,
  State
> {
  constructor(props: Props & RouteComponentProps<{}>) {
    super(props);
    this.state = {
      question: "",
      selectedPracticeArea: "-1",
      language: "en",
      answer: null,
    };
  }

  handleFileUpload = async (files: any) => {
    if (files.length > 0) {
      if (files[0].size > MaxFileSize.size) {
        toast.error({
          message: MaxFileSize.message,
          timeout: 15000,
        });
        return;
      }
      this.setState({ answer: files[0] });
    }
  };

  postDisable = () => {
    const { question, selectedPracticeArea, answer } = this.state;
    return (
      question.length === 0 || selectedPracticeArea === "-1" || answer === null
    );
  };

  handleClose = () => {
    const { closeCallBack } = this.props;
    this.clearModal();
    closeCallBack && closeCallBack(-1);
  };

  clearModal = () => {
    this.setState({
      question: "",
      selectedPracticeArea: "-1",
      language: "en",
      answer: null,
    });
  };

  handlePost = async () => {
    const { answer, question, language, selectedPracticeArea } = this.state;
    const formData = new FormData();
    formData.append("video", answer);
    formData.append(
      "QueModal",
      JSON.stringify({
        Question: question,
        SelectedPracticeArea: +selectedPracticeArea,
        Language: language,
      })
    );
    var res = await Axios.post(endPoint.LawyerProfile.AddQueAns, formData);
    if (res.status === 200) {
      const { closeCallBack } = this.props;
      this.clearModal();
      closeCallBack && closeCallBack(res.data);
    }
  };

  render() {
    const { openModal, practiceAreas, t } = this.props;
    const { question, language, selectedPracticeArea, answer } = this.state;
    return (
      <div>
        <Modal
          show={openModal}
          className="AdminQuestionModal"
          onHide={this.handleClose}
        >
          <Modal.Header closeButton>Add your Qustion and Answer</Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-group user-question-input">
                <label>Enter Your Question</label>
                <input
                  value={question}
                  onChange={(e: any) => {
                    this.setState({ question: e.target.value });
                  }}
                  className="form-control form-control-lg"
                  placeholder="Enter Your Question"
                ></input>
              </div>
              <div className="form-group user-question-language">
                <label>Language</label>
                <select
                  className="form-control form-control-lg"
                  value={language}
                  onChange={(e: any) =>
                    this.setState({ language: e.target.value })
                  }
                >
                  <option value="en">English</option>
                  <option value="es">Spanish</option>
                  <option value="zh">Mandarin</option>
                  <option value="vi">Vietnamese</option>
                </select>
              </div>
              <div className="form-group user-question-language">
                <label>Practice Area</label>
                <select
                  className="form-control form-control-lg"
                  value={selectedPracticeArea}
                  onChange={(e: any) => {
                    this.setState({ selectedPracticeArea: e.target.value });
                  }}
                >
                  <option value="-1">-- Select Practice Area --</option>
                  {practiceAreas.map((data: any) => (
                    <option value={data.ID}>{data.Area}</option>
                  ))}
                </select>
              </div>
              <div className="form-group user-question-language">
                {answer === null && (
                  <button
                    type="button"
                    className="btn btn-lg btn-primary mt-3 w-150px bold position-relative"
                  >
                    Add Your Answer
                    <input
                      id="file-input"
                      type="file"
                      multiple={false}
                      accept={
                        ".MOV, .MP4, .MPEG4, .AVI, .WMV, .FLV, .3GPP, .WebM"
                      }
                      style={{
                        opacity: "0",
                        width: "100%",
                        height: "100%",
                        top: "0",
                        position: "absolute",
                        left: "0",
                      }}
                      onChange={(e: any) => {
                        if (e.target.files && e.target.files.length > 0) {
                          this.handleFileUpload(e.target.files);
                        }
                      }}
                    ></input>
                  </button>
                )}
                {answer !== null && (
                  <div className="form-group user-question-language">
                    <label>Answer</label>
                    <div className="form-control form-control-lg position-relative">
                      {answer.name}
                      <button
                        type="button"
                        className="btn btn-lg btn-primary bold btn-change"
                      >
                        Change
                        <input
                          id="file-input"
                          type="file"
                          multiple={false}
                          accept={
                            ".MOV, .MP4, .MPEG4, .AVI, .WMV, .FLV, .3GPP, .WebM"
                          }
                          style={{
                            opacity: "0",
                            width: "100%",
                            height: "100%",
                            top: "0",
                            position: "absolute",
                            left: "0",
                          }}
                          onChange={(e: any) => {
                            if (e.target.files && e.target.files.length > 0) {
                              this.handleFileUpload(e.target.files);
                            }
                          }}
                        ></input>
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <button
                type="button"
                className="btn btn-lg btn-primary mt-3 w-150px bold"
                disabled={this.postDisable()}
                onClick={this.handlePost}
              >
                Post
              </button>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(withRouter(AddQueAnsModal));
