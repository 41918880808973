import React from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Path } from "../constants";

interface MyRouteProps extends RouteProps {
  component: any;
  projectData?: any;
  rest?: any;
}

const UserRoute: React.SFC<MyRouteProps> = ({
  component: Component,
  ...rest
}) => (
  <>
    <Route
      {...rest}
      render={(props: any) => {
        if (localStorage.getItem("UserToken")) {
          return <Component {...props} />;
        }
        return <Redirect to={Path.UserLogin} />;
      }}
    />
  </>
);

UserRoute.propTypes = {
  component: PropTypes.any,
};

export default UserRoute;
