import { APIUrl } from "Config/ApiConfig";

const endPoint = {
  User: {
    AddQuestion: APIUrl + "/User/AddQuestion",
    getQuestionList: APIUrl + "/User/GetQuestionList",
  },
  LawyerProfile: {
    GetNearByUsersQuestion: APIUrl + "/LawyerProfile/GetNearByUsersQuestion",
    GetAllAnswersForUser: APIUrl + "/LawyerProfile/GetAllAnswersForUser",
    GetAllQuestionsForUser: APIUrl + "/LawyerProfile/GetAllQuestionsForUser",
    AddQueAns: APIUrl + "/LawyerProfile/AddQueAns",
  },
  Public: {
    GetAllPracticeAreas: APIUrl + "/Public/GetAllPracticeAreas",
    SearchZipCode: APIUrl + "/Public/SearchZipCode",
  },
};

export { endPoint };
