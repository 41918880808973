import React from "react";
import "./style.css";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ActionType, Path } from "../../constants";
import Axios from "axios";
import { APIUrl } from "../../Config/ApiConfig";
import { connect } from "react-redux";
import _ from "lodash";
import { withTranslation, WithTranslation } from "react-i18next";

interface Props extends WithTranslation {
  history?: any;
  location?: any;
  hasMenu?: boolean;
  hasUserMenu?: boolean;
  hasAdminMenu?: boolean;
  hasQuestionSearch?: boolean;
  dispatch?: any;
  Token?: string;
  Auth?: any;
  UserToken?: string;
  UserAuth?: any;
  AdminToken?: string;
  AdminAuth?: any;
  userZip?: any;
  language?: any;
  hideLangMenu?: boolean;
}
interface State {
  isMenuOpen: boolean;
  searchQue: string;
  allQuestionsResponse: any;
  predictedQue: any;
}

class Header extends React.Component<Props & RouteComponentProps<{}>, State> {
  constructor(props: Props & RouteComponentProps<{}>) {
    super(props);
    this.state = {
      isMenuOpen: true,
      searchQue: "",
      allQuestionsResponse: [],
      predictedQue: [],
    };
  }
  componentWillMount() {
    const { hasQuestionSearch } = this.props;
    if (hasQuestionSearch) this.getAllQuestionsResponse();
  }

  getAllQuestionsResponse = async () => {
    let response = await Axios.get(
      APIUrl + "/Public/GetAllQuestionsInSequence"
    );
    this.setState({ allQuestionsResponse: response.data });
  };
  handleSearchChange = (search: string) => {
    const { allQuestionsResponse } = this.state;
    this.setState({ searchQue: search });
    let result: any = [];
    let predictedQue: any = [];
    if (search) {
      let searchArr = search.split(" ");

      _.forEach(searchArr, (data: any) => {
        if (data) {
          predictedQue.push(
            _.filter(allQuestionsResponse, (queData) => {
              return queData.Question.toLowerCase().includes(
                data.toLowerCase()
              );
            })
          );
        }
      });
      _.forEach(predictedQue, (data: any) => {
        if (data) {
          _.forEach(data, (qData: any) => {
            let index = _.findIndex(result, { ID: qData.ID });
            if (index > -1) {
              result[index] = { count: result[index].count + 1, ...qData };
            } else {
              result.push({ count: 1, ...qData });
            }
          });
        }
      });
    } else {
      this.setState({ predictedQue: [] });
    }
    this.setState({ predictedQue: _.orderBy(result, ["count"], ["desc"]) });
  };
  highlight = (value: any) => {
    value = "#**#" + value + "#**#";
    const { searchQue } = this.state;
    let searchArr = searchQue.split(" ");
    let newValue: any = value;
    _.forEach(searchArr, (data: any) => {
      if (data) {
        var re;
        if (data === "?") re = "?";
        else re = new RegExp(data, "i");
        let arr = newValue.split(re);
        if (arr.length > 1) {
          newValue = arr[0];
          for (let i = 1; i < arr.length; i++) {
            newValue += "<u>" + data + "</u>" + arr[i];
          }
        }
      }
    });
    return newValue.replace("#**#", "").replace("#**#", "");
  };
  selectQue = (selectedQue: any) => {
    const { history, userZip } = this.props;
    history &&
      history.push(Path.showAns + "/" + userZip + "/" + selectedQue.ID);
    this.setState({ predictedQue: [], searchQue: "" });
  };

  handleLogOut = async () => {
    let response = await Axios.post(APIUrl + "/LogIn/LogOut", {
      Token: this.props.Token,
    });
    if (response.data) {
      this.props.dispatch({ type: ActionType.RemoveToken });
      this.props.history.push(Path.LawyerLogin);
    }
  };

  handleUserLogOut = async () => {
    let response = await Axios.post(APIUrl + "/LogIn/UserLogOut", {
      Token: this.props.UserToken,
    });
    if (response.data) {
      this.props.dispatch({ type: ActionType.RemoveUserToken });
      this.props.history.push(Path.UserLogin);
    }
  };
  handleAdminLogOut = async () => {
    let response = await Axios.post(APIUrl + "/LogIn/AdminLogOut", {
      Token: this.props.AdminToken,
    });
    if (response.data) {
      this.props.dispatch({ type: ActionType.RemoveAdminToken });
      this.props.history.push(Path.AdminLogin);
    }
  };

  render() {
    const { isMenuOpen, searchQue, predictedQue } = this.state;
    const {
      history,
      location,
      hasMenu,
      hasUserMenu,
      hasAdminMenu,
      hasQuestionSearch,
      language,
      dispatch,
      t,
    } = this.props;
    let { hideLangMenu } = this.props;
    let hasAnyMenu = hasMenu || hasUserMenu || hasAdminMenu;
    if (!hideLangMenu) hideLangMenu = hasMenu || hasAdminMenu;
    const langMenu = !hideLangMenu ? (
      <span
        className={
          "menu-item-list float-right" +
          (hasAnyMenu ? " hasAnyMenu" : "") +
          (hasQuestionSearch ? " hasQuestionSearch" : "") +
          (!isMenuOpen ? " hide" : "")
        }
      >
        <select
          value={language}
          className="select-language"
          onChange={(e: any) => {
            dispatch({
              type: ActionType.SetLanguage,
              payload: e.target.value,
            });
          }}
        >
          <option value="en">English</option>
          <option value="es">Spanish</option>
          <option value="zh">Mandarin</option>
          <option value="vi">Vietnamese</option>
        </select>
      </span>
    ) : (
      <></>
    );

    return (
      <div className="header-wrapper">
        <div
          className={"header topnav " + (isMenuOpen ? "" : "responsive")}
          id="myTopnav"
        >
          <span className="logo-span">
            <a
              href="javascript:void(0)"
              className="logo"
              onClick={() => history.push(Path.Home)}
            >
              golawgo
            </a>
          </span>
          {hasQuestionSearch && langMenu}
          {hasQuestionSearch && (
            <div className="header-search-bar">
              <i className="fa fa-search icon"></i>
              <input
                type="text"
                className="form-control"
                placeholder="Type your question here..."
                value={searchQue}
                onChange={(e: any) => this.handleSearchChange(e.target.value)}
                // // onBlur={() => this.setState({ predictedQue: [] })}
                onFocus={(e: any) => this.handleSearchChange(e.target.value)}
              ></input>
            </div>
          )}
          {hasMenu && (
            <span className="menu-item-list float-right">
              <a
                href="javascript:void(0)"
                onClick={() => history.push(Path.LawyerProfile)}
                className={
                  location.pathname === Path.LawyerProfile ? "active" : ""
                }
              >
                Profile
              </a>
              <a
                href="javascript:void(0)"
                onClick={() => history.push(Path.LawyerDashboard)}
                className={
                  location.pathname === Path.LawyerDashboard ? "active" : ""
                }
              >
                Add Answer
              </a>
              <a
                href="javascript:void(0)"
                className=""
                onClick={this.handleLogOut}
              >
                LogOut
              </a>
            </span>
          )}
          {hasUserMenu && (
            <span className="menu-item-list float-right">
              <a
                href="javascript:void(0)"
                onClick={() => history.push(Path.UserQuestionAsk)}
                className={
                  location.pathname === Path.UserQuestionAsk ? "active" : ""
                }
              >
                {t("header.askQuestion")}
              </a>
              <a
                href="javascript:void(0)"
                onClick={() => history.push(Path.UserFavoriteLawyers)}
                className={
                  location.pathname === Path.UserFavoriteLawyers ? "active" : ""
                }
              >
                {t("header.favoriteAttorneys")}
              </a>
              <a
                href="javascript:void(0)"
                onClick={() => history.push(Path.UserFavoriteAnswers)}
                className={
                  location.pathname === Path.UserFavoriteAnswers ? "active" : ""
                }
              >
                {t("header.favoriteAnswers")}
              </a>
              <a
                href="javascript:void(0)"
                className=""
                onClick={this.handleUserLogOut}
              >
                {t("header.logOut")}
              </a>
            </span>
          )}
          {hasAdminMenu && (
            <span className="menu-item-list float-right">
              <a
                href="javascript:void(0);"
                onClick={() => history.push(Path.AdminInviteAttorney)}
                className={
                  location.pathname === Path.AdminInviteAttorney ? "active" : ""
                }
              >
                Invite Attorney
              </a>
              <a
                href="javascript:void(0)"
                onClick={() => history.push(Path.AdminPracticeAreas)}
                className={
                  location.pathname === Path.AdminPracticeAreas ? "active" : ""
                }
              >
                Practice Areas
              </a>
              <a
                href="javascript:void(0)"
                onClick={() => history.push(Path.AdminQuestions)}
                className={
                  location.pathname === Path.AdminQuestions ? "active" : ""
                }
              >
                Questions
              </a>
              <a
                href="javascript:void(0)"
                className=""
                onClick={this.handleAdminLogOut}
              >
                LogOut
              </a>
            </span>
          )}
          {!hasQuestionSearch && langMenu}
          {hasAnyMenu && (
            <span>
              {isMenuOpen && (
                <a
                  href="javascript:void(0)"
                  className="icon"
                  onClick={() => this.setState({ isMenuOpen: !isMenuOpen })}
                >
                  <i className="fa fa-bars"></i>
                </a>
              )}
              {!isMenuOpen && (
                <a
                  href="javascript:void(0)"
                  className="icon"
                  onClick={() => this.setState({ isMenuOpen: !isMenuOpen })}
                >
                  <i className="fa fa-times"></i>
                </a>
              )}
            </span>
          )}

          <div className="header-right"></div>
        </div>
        <div
          className={
            "text-left predicted-questions " +
            (predictedQue.length > 0 ? "" : "hide")
          }
        >
          <div>
            {predictedQue &&
              predictedQue.map((data: any, index: number) => (
                <div
                  key={index}
                  onClick={() => this.selectQue(data)}
                  dangerouslySetInnerHTML={{
                    __html: this.highlight(data.Question),
                  }}
                />
              ))}
          </div>
        </div>
        {predictedQue && predictedQue.length > 0 && (
          <div
            className="back-cover"
            onClick={() => this.setState({ predictedQue: [] })}
          ></div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  Token: state.auth.Token,
  Auth: state.auth.Auth,
  UserAuth: state.auth.UserAuth,
  UserToken: state.auth.UserToken,
  AdminToken: state.auth.AdminToken,
  AdminAuth: state.auth.AdminAuth,
  language: state.language.type,
});
export default connect(
  mapStateToProps,
  null
)(withTranslation()(withRouter(Header)));
