import { ActionType } from "../../constants";

const initialState = {
  Token: localStorage.getItem("Token"),
  Auth:
    localStorage.getItem("Auth") &&
    JSON.parse("" + localStorage.getItem("Auth")),
  UserToken: localStorage.getItem("UserToken"),
  UserAuth:
    localStorage.getItem("UserAuth") &&
    JSON.parse("" + localStorage.getItem("UserAuth")),
  AdminToken: localStorage.getItem("AdminToken"),
  AdminAuth:
    localStorage.getItem("AdminAuth") &&
    JSON.parse("" + localStorage.getItem("AdminAuth")),
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ActionType.SaveToken:
      localStorage.setItem("Token", action.payload.Token);
      localStorage.setItem("Auth", JSON.stringify(action.payload));
      return {
        ...state,
        Token: action.payload.Token,
        Auth: action.payload,
      };
    case ActionType.RemoveToken:
      localStorage.removeItem("Token");
      localStorage.removeItem("Auth");
      return {
        ...state,
        Token: null,
        Auth: null,
      };

    case ActionType.SaveUserToken:
      localStorage.setItem("UserToken", action.payload.Token);
      localStorage.setItem("UserAuth", JSON.stringify(action.payload));
      return {
        ...state,
        UserToken: action.payload.Token,
        UserAuth: action.payload,
      };
    case ActionType.RemoveUserToken:
      localStorage.removeItem("UserToken");
      localStorage.removeItem("UserAuth");
      return {
        ...state,
        UserToken: null,
        UserAuth: null,
      };

    case ActionType.SaveAdminToken:
      localStorage.setItem("AdminToken", action.payload.Token);
      localStorage.setItem("AdminAuth", JSON.stringify(action.payload));
      return {
        ...state,
        AdminToken: action.payload.Token,
        AdminAuth: action.payload,
      };
    case ActionType.RemoveAdminToken:
      localStorage.removeItem("AdminToken");
      localStorage.removeItem("AdminAuth");
      return {
        ...state,
        AdminToken: null,
        AdminAuth: null,
      };

    default:
      return state;
  }
}
