import React, { Component, Suspense } from "react";
import "./App.css";

import { Switch, Route } from "react-router-dom";
import { Router, Redirect } from "react-router";
import { createBrowserHistory } from "history";
import { connect } from "react-redux";

import { Path } from "./constants";

import GuestRoute from "./routes/GuestRoute";
import PrivateRoute from "./routes/PrivateRoute";
import UserRoute from "./routes/UserRoute";
import AdminRoute from "./routes/AdminRoute";

import Loader from "./utils/Loader";
import ComingSoon from "./utils/ComingSoon";

import "react-widgets/dist/css/react-widgets.css";

//Landing Page
import Home from "./Components/Landing/Home";
import PasswordSetUp from "./Components/SignUp/PasswordSetUp";
import AddAnswer from "Components/Dashboard/Lawyer/AddAnswer";
//Lawyer
const SignUp = React.lazy(() => import("./Components/SignUp"));
// const PasswordSetUp = React.lazy(() =>
//   import("./Components/SignUp/PasswordSetUp")
// );
const LogIn = React.lazy(() => import("./Components/LogIn/Lawyer"));
const LawyerDashboard = React.lazy(
  () => import("./Components/Dashboard/Lawyer")
);
const LawyerProfile = React.lazy(
  () => import("./Components/Dashboard/Lawyer/LawyerProfile")
);
const LawyerAddAnswer = React.lazy(
  () => import("./Components/Dashboard/Lawyer/AddAnswer")
);

//User
const UserSignUp = React.lazy(() => import("./Components/SignUp/User"));
const UserLogIn = React.lazy(() => import("./Components/LogIn/User"));
const UserDashboard = React.lazy(() => import("./Components/Dashboard/User"));
const UserFavoriteAnswers = React.lazy(
  () => import("./Components/Dashboard/User/FavoriteAnswers")
);
const UserFavoriteLawyers = React.lazy(
  () => import("./Components/Dashboard/User/FavoriteLawyers")
);
const UserQuestionAsk = React.lazy(
  () => import("./Components/Dashboard/User/Questions/Ask")
);

//Admin
const AdminLogin = React.lazy(() => import("./Components/LogIn/Admin"));
const AdminDashboard = React.lazy(() => import("./Components/Dashboard/Admin"));
const AdminPracticeAreas = React.lazy(
  () => import("./Components/Dashboard/Admin/PracticeAreas")
);
const AdminQuestions = React.lazy(
  () => import("./Components/Dashboard/Admin/Questions")
);
const InviteAttorney = React.lazy(
  () => import("./Components/Dashboard/Admin/InviteAttorney")
);

const LawyerPublicProfile = React.lazy(
  () => import("./Components/LawyerPublicProfile")
);
const SwipeAnswer = React.lazy(() => import("./Components/SwipeAnswer"));

const AnswerView = React.lazy(
  () => import("./Components/AnswerView/AnswerView")
);
const HubSpoke = React.lazy(() => import("./Components/HubSpoke"));

interface Props {
  isLoading?: any;
}
interface States {
  access: boolean;
}

const history = createBrowserHistory();
class App extends Component<Props, States> {
  constructor(props: Props) {
    super(props);
    let hasAccess = false;
    if (localStorage.getItem("hasBetaAccess")) {
      hasAccess = true;
    } else if (window.location.href.indexOf("betaAccess") > -1) {
      hasAccess = true;
      localStorage.setItem("hasBetaAccess", "true");
    }
    this.state = {
      access: hasAccess,
    };
  }
  render() {
    const { access } = this.state;
    return (
      <Suspense fallback={"loading"}>
        <>
          {access && (
            <Router history={history}>
              <Switch>
                <Route exact path="/" component={Home}></Route>
                <React.Suspense fallback={<Loader></Loader>}>
                  <GuestRoute
                    exact
                    path={Path.SignUp + "/:ID"}
                    component={PasswordSetUp}
                  ></GuestRoute>
                  <GuestRoute
                    exact
                    path={Path.SignUp}
                    component={SignUp}
                  ></GuestRoute>
                  <GuestRoute
                    exact
                    path={Path.UserSignUp}
                    component={UserSignUp}
                  ></GuestRoute>
                  <GuestRoute
                    exact
                    path={Path.LawyerLogin}
                    component={LogIn}
                  ></GuestRoute>
                  <GuestRoute
                    exact
                    path={Path.AdminLogin}
                    component={AdminLogin}
                  ></GuestRoute>
                  <GuestRoute
                    exact
                    path={Path.UserLogin}
                    component={UserLogIn}
                  ></GuestRoute>
                  <AdminRoute
                    exact
                    path={Path.AdminDashboard}
                    component={AdminDashboard}
                  ></AdminRoute>
                  <AdminRoute
                    exact
                    path={Path.AdminPracticeAreas}
                    component={AdminPracticeAreas}
                  ></AdminRoute>
                  <AdminRoute
                    exact
                    path={Path.AdminQuestions}
                    component={AdminQuestions}
                  ></AdminRoute>
                  <AdminRoute
                    exact
                    path={Path.AdminInviteAttorney}
                    component={InviteAttorney}
                  ></AdminRoute>
                  <PrivateRoute
                    exact
                    path={Path.LawyerDashboard}
                    component={AddAnswer}
                  ></PrivateRoute>
                  <PrivateRoute
                    exact
                    path={Path.LawyerAddAnswer}
                    component={AddAnswer}
                  ></PrivateRoute>
                  <PrivateRoute
                    exact
                    path={Path.LawyerProfile}
                    component={LawyerProfile}
                  ></PrivateRoute>
                  <UserRoute
                    exact
                    path={Path.UserDashboard}
                    component={UserDashboard}
                  ></UserRoute>
                  <UserRoute
                    exact
                    path={Path.UserFavoriteAnswers}
                    component={UserFavoriteAnswers}
                  ></UserRoute>
                  <UserRoute
                    exact
                    path={Path.UserFavoriteLawyers}
                    component={UserFavoriteLawyers}
                  ></UserRoute>
                  <UserRoute
                    exact
                    path={Path.UserQuestionAsk}
                    component={UserQuestionAsk}
                  ></UserRoute>
                  <Route
                    path={Path.showAns + "/:ZipCode/:QID"}
                    component={SwipeAnswer}
                  ></Route>
                  <Route
                    path={Path.FindPublicAnswer + "/:AnsID"}
                    component={AnswerView}
                  ></Route>
                  <Route
                    path={Path.LawyerPublicProfile + "/:lawyerID"}
                    component={LawyerPublicProfile}
                  ></Route>
                  <Route
                    path={Path.HubSpokeQuestionList}
                    component={HubSpoke}
                  ></Route>
                </React.Suspense>
                <Redirect to={"/"}></Redirect>
              </Switch>
            </Router>
          )}
          {!access && <ComingSoon></ComingSoon>}
          {this.props.isLoading && <Loader></Loader>}
        </>
      </Suspense>
    );
  }
}

const mapStateToProps = (state: any) => ({
  isLoading: state.loader.isLoading,
});
export default connect(mapStateToProps, null)(App);
