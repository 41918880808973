import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { endPoint } from "constants/ApiPoints";
import Axios from "axios";
import { getLanguageString } from "utils/common";
import QuestionModal from "../QuestionModal/QuestionModal";
import _ from "lodash";
import AddQueAnsModal from "../QuestionModal/AddQueAnsModal";

interface Props {
  history: History;
}
interface State {
  response: any;
  openQuestion: boolean;
  openQuestionID: number;
  openAddQuestion: boolean;
  practiceAreas: any;
  selectedPracticeArea: any;
  language: string;
  searchQuestion: any;
}

class SearchQuestion extends React.Component<
  Props & RouteComponentProps<{}>,
  State
> {
  constructor(props: Props & RouteComponentProps<{}>) {
    super(props);
    this.state = {
      response: [],
      openQuestion: false,
      openQuestionID: -1,
      openAddQuestion: false,
      practiceAreas: [],
      language: "en",
      selectedPracticeArea: "-1",
      searchQuestion: "",
    };
  }

  componentDidMount() {
    this.GetAllPracticeAreas();
  }

  GetAllPracticeAreas = async () => {
    let response = await Axios.get(endPoint.Public.GetAllPracticeAreas);
    this.setState({ practiceAreas: response.data });
  };

  getQuestionList = async () => {
    const { language, selectedPracticeArea } = this.state;
    if (selectedPracticeArea !== "-1") {
      let response = await Axios.get(
        endPoint.LawyerProfile.GetAllQuestionsForUser +
          "?Lang=" +
          language +
          "&PracticeArea=" +
          selectedPracticeArea
      );
      this.setState({ response: response.data });
    }
  };

  openQuestionModal = (questionID: number) => {
    this.setState({ openQuestion: true, openQuestionID: questionID });
  };
  closeQuestionModal = () => {
    this.setState({ openQuestion: false, openQuestionID: -1 });
  };

  openAddQuestionModal = () => {
    this.setState({ openAddQuestion: true });
  };
  closeAddQuestionModal = (newQuestionID: number) => {
    if (newQuestionID > 0) {
      this.setState({
        openAddQuestion: false,
        openQuestion: true,
        openQuestionID: newQuestionID,
      });
    }
    this.setState({ openAddQuestion: false });
  };

  handleChangePracticeArea = (e: any) => {
    this.setState(
      { selectedPracticeArea: e.target.value, searchQuestion: "" },
      this.getQuestionList
    );
  };
  handleLanguageChange = (e: any) => {
    this.setState(
      { language: e.target.value, searchQuestion: "" },
      this.getQuestionList
    );
  };
  handleSearchQuestionChange = (e: any) => {
    this.setState({ searchQuestion: e.target.value });
  };

  render() {
    const {
      response,
      openQuestion,
      openQuestionID,
      openAddQuestion,
      practiceAreas,
      language,
      selectedPracticeArea,
      searchQuestion,
    } = this.state;
    let filteredData = response;
    if (searchQuestion !== "") {
      let searchArr = searchQuestion.split(" ");
      filteredData = _.filter(response, (data) => {
        let result = true;
        _.forEach(searchArr, (searchKey: any) => {
          if (searchKey !== "")
            result =
              result &&
              data.Question.toLowerCase().indexOf(searchKey.toLowerCase()) > -1;
        });
        return result;
      });
    }
    return (
      <>
        <div className="lawyer-search-question">
          <div className="filter-wrapper">
            <div className="first-line">
              <div className="form-group select-language">
                <label>Language</label>
                <select
                  className="form-control form-control-lg"
                  value={language}
                  onChange={this.handleLanguageChange}
                >
                  <option value="all">All</option>
                  <option value="en">English</option>
                  <option value="es">Spanish</option>
                  <option value="zh">Mandarin</option>
                  <option value="vi">Vietnamese</option>
                </select>
              </div>
              <div className="form-group practice-area">
                <label>Practice Area</label>
                <select
                  className="form-control form-control-lg"
                  value={selectedPracticeArea}
                  onChange={this.handleChangePracticeArea}
                >
                  <option value="-1">-- Select practice area --</option>
                  {practiceAreas.map((data: any) => (
                    <option value={data.ID}>{data.Area}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group search-question">
              <label>Search Question</label>
              <input
                value={searchQuestion}
                onChange={this.handleSearchQuestionChange}
                className="form-control form-control-lg"
                disabled={selectedPracticeArea === "-1"}
                placeholder="Enter question here to search"
              ></input>
            </div>
            <div className="form-group search-question">
              <label></label>
              <button
                className="btn btn-primary btn-add-ans btn-lg w-100"
                onClick={() => this.openAddQuestionModal()}
              >
                Add your Question with Answer
              </button>
            </div>
          </div>
          <hr></hr>
          <div className="question-list-wrapper">
            {selectedPracticeArea === "-1" && (
              <div className="empty-view-wrapper">
                <div className="empty-view">
                  <div>
                    Please select a relevant practice area to start searching
                    questions
                  </div>
                </div>
              </div>
            )}
            {selectedPracticeArea !== "-1" && (
              <>
                {filteredData.length === 0 && (
                  <div className="empty-view-wrapper">
                    <div className="empty-view">
                      <div>
                        No questions found for the selected practice area
                      </div>
                    </div>
                  </div>
                )}
                {filteredData.map((data: any, index: number) => (
                  <div
                    className="question-item border rounded p-2 pl-3 pr-3 position-relative"
                    key={index}
                  >
                    <div className="detail-wrapper">
                      <h3 className="question">
                        {data.Question}
                        <span className="language">
                          {" [" + getLanguageString(data.Language) + "]"}
                        </span>
                      </h3>
                      <h5>{data.Area}</h5>
                    </div>
                    <button
                      className="btn btn-primary btn-add-ans"
                      onClick={() => this.openQuestionModal(data.ID)}
                    >
                      Add Answer
                    </button>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
        {openQuestion && (
          <QuestionModal
            openModal={openQuestion}
            QueID={openQuestionID}
            closeCallBack={this.closeQuestionModal}
            refreshList={this.getQuestionList}
          ></QuestionModal>
        )}
        {openAddQuestion && (
          <AddQueAnsModal
            openModal={openAddQuestion}
            closeCallBack={this.closeAddQuestionModal}
            practiceAreas={practiceAreas}
          ></AddQueAnsModal>
        )}
      </>
    );
  }
}

export default withRouter(SearchQuestion);
