import React from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Path } from "../constants";

interface MyRouteProps extends RouteProps {
  component: any;
  rest?: any;
}
const GuestRoute: React.SFC<MyRouteProps> = ({
  component: Component,
  ...rest
}) => (
  <>
    <Route
      {...rest}
      render={(props: any) => {
        if (localStorage.getItem("Token")) {
          return <Redirect to={Path.LawyerDashboard} />;
        } else if (localStorage.getItem("UserToken")) {
          return <Redirect to={Path.UserDashboard} />;
        } else if (localStorage.getItem("AdminToken")) {
          return <Redirect to={Path.AdminDashboard} />;
        }
        return <Component {...props} />;
      }}
    />
  </>
);

GuestRoute.propTypes = {
  component: PropTypes.any,
};
export default GuestRoute;
