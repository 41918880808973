import { combineReducers } from "redux";
import authReducer from "./authReducer";
import loadingReducer from "./loadingReducer";
import languageReducer from "./languageReducer";

export default combineReducers({
  auth: authReducer,
  loader: loadingReducer,
  language: languageReducer,
});
