import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import Header from "Components/Header";
import SmallFooter from "Components/Footer/SmallFooter";
import { Helmet } from "react-helmet";
import TabBar from "@material/react-tab-bar";
import Tab from "@material/react-tab";
import "@material/react-tab-bar/dist/tab-bar.css";
import "@material/react-tab-scroller/dist/tab-scroller.css";
import "@material/react-tab/dist/tab.css";
import "@material/react-tab-indicator/dist/tab-indicator.css";
import "./style.css";
import NearbyQuestion from "./nearbyQuestion";
import YourAnswers from "./yourAnswers";
import SearchQuestion from "./searchQuestion";

interface Props {
  history: History;
}
interface State {
  activeTabIndex: number;
}

class AddAnswer extends React.Component<
  Props & RouteComponentProps<{}>,
  State
> {
  constructor(props: Props & RouteComponentProps<{}>) {
    super(props);
    this.state = { activeTabIndex: 0 };
  }
  render() {
    const { activeTabIndex } = this.state;
    return (
      <>
        <Helmet>
          <title>Add Answer</title>
        </Helmet>
        <Header hasMenu></Header>
        <div className="container add-answer">
          <div className="tab-wrapper mb-4">
            <TabBar
              activeIndex={activeTabIndex}
              handleActiveIndexUpdate={(index: number) =>
                this.setState({ activeTabIndex: index })
              }
            >
              <Tab>
                <span className="mdc-tab__text-label">
                  Nearby user's questions
                </span>
              </Tab>
              <Tab>
                <span className="mdc-tab__text-label">
                  Search question & Add answer
                </span>
              </Tab>
              <Tab>
                <span className="mdc-tab__text-label">Your Answers</span>
              </Tab>
            </TabBar>
          </div>
          {activeTabIndex == 0 && <NearbyQuestion />}
          {activeTabIndex == 1 && <SearchQuestion />}
          {activeTabIndex == 2 && <YourAnswers />}
        </div>
        <SmallFooter></SmallFooter>
      </>
    );
  }
}

export default withRouter(AddAnswer);
