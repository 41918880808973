import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import Axios from "axios";
import { endPoint } from "constants/ApiPoints";
import { getLanguageString } from "utils/common";
import QuestionModal from "../QuestionModal/QuestionModal";
import _ from "lodash";

interface Props {
  history: History;
}
interface State {
  response: any;
  openQuestion: boolean;
  openQuestionID: number;
  language: string;
  selectedPracticeArea: string;
}

class YourAnswers extends React.Component<
  Props & RouteComponentProps<{}>,
  State
> {
  constructor(props: Props & RouteComponentProps<{}>) {
    super(props);
    this.state = {
      response: [],
      openQuestion: false,
      openQuestionID: -1,
      language: "all",
      selectedPracticeArea: "all",
    };
  }

  componentDidMount() {
    this.getQuestionList();
  }
  getQuestionList = async () => {
    let response = await Axios.get(
      endPoint.LawyerProfile.GetAllAnswersForUser + "?Lang=all"
    );
    this.setState({ response: response.data });
  };

  openQuestionModal = (questionID: number) => {
    this.setState({ openQuestion: true, openQuestionID: questionID });
  };
  closeQuestionModal = () => {
    this.setState({ openQuestion: false, openQuestionID: -1 });
  };

  handleLanguageChange = (e: any) => {
    this.setState({ language: e.target.value });
  };
  handleChangePracticeArea = (e: any) => {
    this.setState({ selectedPracticeArea: e.target.value });
  };

  render() {
    const {
      response,
      openQuestion,
      openQuestionID,
      language,
      selectedPracticeArea,
    } = this.state;
    let langList = _.uniqBy(response, "Language");
    let practiceAreaList = _.uniqBy(response, "Area");
    let filteredData = response;
    if (language !== "all" || selectedPracticeArea !== "all") {
      filteredData = _.filter(response, (data) => {
        return (
          (language !== "all" ? data.Language == language : true) &&
          (selectedPracticeArea !== "all"
            ? data.Area == selectedPracticeArea
            : true)
        );
      });
    }
    return (
      <>
        <div className="near-by-question pb-5">
          <div className="filter-wrapper">
            <div className="first-line">
              <div className="form-group select-language">
                <label>Language</label>
                <select
                  className="form-control form-control-lg"
                  value={language}
                  onChange={this.handleLanguageChange}
                >
                  <option value="all">All</option>
                  {langList.map((data: any) => (
                    <option value={data.Language}>
                      {getLanguageString(data.Language)}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group practice-area">
                <label>Practice Area</label>
                <select
                  className="form-control form-control-lg"
                  value={selectedPracticeArea}
                  onChange={this.handleChangePracticeArea}
                >
                  <option value="all">All</option>
                  {practiceAreaList.map((data: any) => (
                    <option value={data.Area}>{data.Area}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <hr></hr>
          {response.length !== 0 && (
            <>
              <div className="question-list-wrapper">
                {filteredData &&
                  filteredData.map((data: any, index: number) => (
                    <div className="question-item border rounded p-2 pl-3 pr-3 position-relative">
                      <div className="detail-wrapper">
                        <h3 className="question">
                          {data.Question}
                          <span className="language">
                            {" [" + getLanguageString(data.Language) + "]"}
                          </span>
                        </h3>
                        <h5>{data.Area}</h5>
                      </div>
                      <button
                        className="btn btn-primary btn-add-ans"
                        onClick={() => this.openQuestionModal(data.ID)}
                      >
                        View
                      </button>
                    </div>
                  ))}
              </div>
              {filteredData.length === 0 && (
                <div className="empty-view-wrapper">
                  <div className="empty-view">
                    <div>
                      No questions found for the selected filter options
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {response.length === 0 && (
            <div className="empty-view-wrapper">
              <div className="empty-view">
                <div>
                  You have not added any answer yet. This is where you'll see a
                  list of your added answers question
                </div>
              </div>
            </div>
          )}
        </div>
        {openQuestion && (
          <QuestionModal
            openModal={openQuestion}
            QueID={openQuestionID}
            closeCallBack={this.closeQuestionModal}
            refreshList={this.getQuestionList}
          ></QuestionModal>
        )}
      </>
    );
  }
}

export default withRouter(YourAnswers);
