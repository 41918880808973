import { ActionType } from "../../constants";

const initialState = {
  isLoading: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ActionType.StartLoading:
      return {
        ...state,
        isLoading: true,
      };
    case ActionType.StopLoading:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
