import _ from "lodash";
import { _getValue } from "./lodashWrapper";

const formatNumber = (value: any) => {
  const removeSign = value.toString().replace("$", "");
  return `$${removeSign.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
};

function formatMoney(number: any) {
  //https://stackoverflow.com/questions/149055/how-to-format-numbers-as-currency-string
  let decPlaces: any = "";
  let decSep: any;
  let thouSep: any;
  decPlaces = 2;
  decSep = typeof decSep === "undefined" ? "." : decSep;
  thouSep = typeof thouSep === "undefined" ? "," : thouSep;
  var sign = number < 0 ? "-" : "";
  var i: any = String(
    parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
  );
  var j: any = 0;
  j = (j = i.length) > 3 ? j % 3 : 0;

  return (
    "$" +
    sign +
    (j ? i.substr(0, j) + thouSep : "") +
    i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
    (decPlaces
      ? decSep +
        Math.abs(number - i)
          .toFixed(decPlaces)
          .slice(2)
      : "")
  );
}

const getLanguageString = (code: string) => {
  switch (code) {
    case "en":
      return "English";
    case "es":
      return "Spanish";
    case "zh":
      return "Mandarin";
    case "vi":
      return "Vietnamese";
    default:
      return "English";
  }
};

const allowDigitOnly = (event: any) => {
  if (!isNumericInput(event)) {
    event.preventDefault();
  }
};

const abbreviateNumber = (value: any) => {
  //https://stackoverflow.com/questions/10599933/convert-long-number-into-abbreviated-string-in-javascript-with-a-special-shortn/32638472
  let isMinus = false;
  value = parseInt(value);
  if (value < 0) {
    isMinus = true;
    value = value * -1;
  }
  var newValue: any = value;
  if (value >= 1000) {
    var suffixes = ["", "k", "m", "b", "t"];
    var suffixNum = Math.floor(("" + value).length / 3);
    if (suffixNum > 4) suffixNum = 4;
    var shortValue: any = "";
    for (var precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum !== 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(precision)
      );
      var dotLessShortValue = (shortValue + "").replace(/[^a-zA-Z 0-9]+/g, "");
      if (dotLessShortValue.length <= 2) {
        break;
      }
    }
    if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);
    newValue = shortValue + suffixes[suffixNum];
  }
  return (isMinus ? "-" : "") + newValue;
};

const reFormatNumber = (value: any) => {
  const removeSign = value.toString().replace("$", "");
  const removeChar = removeSign
    .split("")
    .filter(function (c: any) {
      return c !== ",";
    })
    .join("");
  return removeChar;
};

const isNumericInput = (event: any) => {
  const key = event.keyCode;
  return (
    (key >= 48 && key <= 57) || // Allow number line
    (key >= 96 && key <= 105) || // Allow number pad
    key === 190 ||
    key === 110
  );
};

const isModifierKey = (event: any) => {
  const key = event.keyCode;
  return (
    event.shiftKey === true ||
    key === 35 ||
    key === 36 || // Allow Shift, Home, End
    key === 8 ||
    key === 9 ||
    key === 13 ||
    key === 46 || // Allow Backspace, Tab, Enter, Delete
    (key > 36 && key < 41) || // Allow left, up, right, down
    // Allow Ctrl/Command + A,C,V,X,Z
    ((event.ctrlKey === true || event.metaKey === true) &&
      (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
  );
};

const enforceFormat = (event: any) => {
  // Input must be of a valid number format or a modifier key, and not longer than ten digits
  if (!isNumericInput(event) && !isModifierKey(event)) {
    event.preventDefault();
  }
};
const _getDateFormat = (date: any, separator: string = "/") => {
  // https://stackoverflow.com/questions/17093796/date-formatting-with-without-moment-js
  const timeStr = date;
  const intermediate = timeStr.split("T");
  const newStr =
    intermediate[0].split("-").join("/") +
    " " +
    intermediate[1].split(".")[0] +
    " GMT";
  const newDate = new Date(newStr);
  const day = ("0" + newDate.getUTCDate()).slice(-2);
  const month = `0${1 + newDate.getUTCMonth()}`.slice(-2);
  const year = newDate.getFullYear().toString().substr(2);
  return `${month}${separator}${day}${separator}${year}`;
};

const _getDateFormatWthFullMonth = (date: any) => {
  var monthName = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const timeStr = date;
  const intermediate = timeStr.split("T");
  const newStr =
    intermediate[0].split("-").join("/") +
    " " +
    intermediate[1].split(".")[0] +
    " GMT";
  const newDate = new Date(newStr);
  const day = ("0" + newDate.getUTCDate()).slice(-2);
  const year = newDate.getFullYear();
  return `${monthName[newDate.getUTCMonth()]} ${day}, ${year}`;
};

const _getDateFormatWithFullYear = (date: any, separator: string = "/") => {
  // https://stackoverflow.com/questions/17093796/date-formatting-with-without-moment-js
  const timeStr = date;
  const intermediate = timeStr.split("T");
  const newStr =
    intermediate[0].split("-").join("/") +
    " " +
    intermediate[1].split(".")[0] +
    " GMT";
  const newDate = new Date(newStr);
  const day = ("0" + newDate.getUTCDate()).slice(-2);
  const month = `0${1 + newDate.getUTCMonth()}`.slice(-2);
  const year = newDate.getFullYear().toString();
  return `${month}${separator}${day}${separator}${year}`;
};

const _getTimeFormat = (date: any) => {
  const timeStr = date;
  const intermediate = timeStr.split("T");
  const newStr =
    intermediate[0].split("-").join("/") +
    " " +
    intermediate[1].split(".")[0] +
    " GMT";
  const newDate = new Date(newStr);
  const H = (
    "0" +
    (newDate.getHours() > 12 //ternary used for changing time format 24hr to 12hr
      ? newDate.getHours() - 12
      : newDate.getHours())
  )
    .slice(-2) // change 01,02,010,011,012 -> 01,02,10,11,12
    .replace("00", "12"); //used for replacing 00:10AM to 12:10AM
  const M = ("0" + newDate.getMinutes()).slice(-2);
  const A = newDate.getHours() >= 12 ? "pm" : "am";
  return `${H}:${M} ${A}`;
};

const formatAmount = (value: any) => {
  if (value === undefined || value == null) value = "";
  value = "" + value;
  value = value.replace("$", "");
  if (value.trim() === "") {
    value = "";
  } else if (Number(value) !== NaN) {
    value =
      parseFloat(value) > 9.99
        ? "" + parseFloat(value).toFixed(2)
        : "0" + parseFloat(value).toFixed(2);
    value = "$ " + value;
  }

  return value;
};

export {
  formatNumber,
  enforceFormat,
  reFormatNumber,
  _getDateFormat,
  _getTimeFormat,
  getLanguageString,
  isNumericInput,
  allowDigitOnly,
  formatAmount,
  formatMoney,
  _getDateFormatWthFullMonth,
  abbreviateNumber,
  _getDateFormatWithFullYear,
};
