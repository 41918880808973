import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";

import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import "izitoast/dist/css/iziToast.min.css";
import store from "./redux/store";
import "./utils/Interceptor";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "./utils/Loader";
import "./utils/i18n";
import { I18nextProvider } from "react-i18next";
import App from "./App";

ReactDOM.render(
  <Suspense fallback={null}>
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  </Suspense>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
