const Path = {
  //Home
  Home: "/",
  //SignUp
  SignUp: "/signup/lawyer",
  UserSignUp: "/signup/user",

  //Login
  LawyerLogin: "/login/law",
  UserLogin: "/user/login",
  AdminLogin: "/admin/login",

  //LawyerDashboard
  LawyerDashboard: "/lawyer/dashboard",
  LawyerProfile: "/lawyer/profile",
  LawyerAddAnswer: "/lawyer/AddAnswer",

  //UserDashboard
  UserDashboard: "/user/dashboard",
  UserFavoriteLawyers: "/user/favorite/lawyers",
  UserFavoriteAnswers: "/user/favorite/answers",
  UserQuestionAsk: "/user/question/ask",

  //AdminDashboard
  AdminDashboard: "/admin/dashboard",
  AdminQuestions: "/admin/questions",
  AdminPracticeAreas: "/admin/practiceareas",
  AdminInviteAttorney: "/admin/invite/attorney",

  LawyerPublicProfile: "/find/lawyer",
  FindPublicAnswer: "/find/answer",
  HubSpokeQuestionList: "/hub/questionlist",
  showAns: "/showAns",
};
const RegEx = {
  // eslint-disable-next-line  no-useless-escape
  email: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, //https://hype.codes/how-validate-email-address-javascript
  // eslint-disable-next-line  no-useless-escape
  password: /^(?=.{8,})(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[@#$%^!&*+=]).*$/, //https://stackoverflow.com/questions/5142103/regex-to-validate-password-strength
};

const ActionType = {
  SaveToken: "Save_Token",
  SaveUserToken: "Save_User_Token",
  SaveAdminToken: "Save_Admin_Token",
  RemoveToken: "Remove_Token",
  RemoveUserToken: "Remove_User_Token",
  RemoveAdminToken: "Remove_Admin_Token",
  StartLoading: "Start_Loading",
  StopLoading: "Stop_Loading",
  SetLanguage: "Set_Language",
};

const MaxFileSize = {
  size: 104857600,
  message: "Please try again with a file that is smaller than 100 MB",
};

export { Path, RegEx, ActionType, MaxFileSize };
