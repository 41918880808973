import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";

class SmallFooter extends React.Component<WithTranslation> {
  render() {
    const { t } = this.props;
    return (
      <div className="home small-footer">
        <footer className="site-footer pt-0">
          <div className="container">
            <div className="row">
              <div className="col-12 footer-bottom pt-0 mt-0">
                <div>
                  {t("footer.copyRight.start")}{" "}
                  <i className="fa fa-copyright"></i>
                  2020 <strong style={{ color: "#fff" }}>golawgo</strong> |{" "}
                  {t("footer.copyRight.end")}
                </div>
              </div>
            </div>
          </div>
        </footer>
        <script src="js/jquery.min.js"></script>
        <script src="js/popper.min.js"></script>
        <script src="js/bootstrap.min.js"></script>
        <script src="js/slick.min.js"></script>
      </div>
    );
  }
}
export default withTranslation()(SmallFooter);
