import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import Axios from "axios";
import { endPoint } from "constants/ApiPoints";
import { getLanguageString } from "utils/common";
import QuestionModal from "../QuestionModal/QuestionModal";
import _ from "lodash";

interface Props {
  history: History;
}
interface State {
  response: any;
  openQuestion: boolean;
  openQuestionID: number;
  language: string;
  selectedPracticeArea: string;
  selectedRange: string;
}

class NearbyQuestion extends React.Component<
  Props & RouteComponentProps<{}>,
  State
> {
  constructor(props: Props & RouteComponentProps<{}>) {
    super(props);
    this.state = {
      response: [],
      openQuestion: false,
      openQuestionID: -1,
      language: "all",
      selectedPracticeArea: "all",
      selectedRange: "city",
    };
  }

  componentDidMount() {
    this.getQuestionList();
  }
  getQuestionList = async () => {
    const { selectedRange } = this.state;
    let response = await Axios.get(
      endPoint.LawyerProfile.GetNearByUsersQuestion + "?range=" + selectedRange
    );
    this.setState({ response: response.data });
  };

  openQuestionModal = (questionID: number) => {
    this.setState({ openQuestion: true, openQuestionID: questionID });
  };
  closeQuestionModal = () => {
    this.setState({ openQuestion: false, openQuestionID: -1 });
  };

  handleLanguageChange = (e: any) => {
    this.setState({ language: e.target.value });
  };
  handleChangePracticeArea = (e: any) => {
    this.setState({ selectedPracticeArea: e.target.value });
  };
  handleRangeChange = (e: any) => {
    this.setState({ selectedRange: e.target.value }, this.getQuestionList);
  };

  render() {
    const {
      response,
      openQuestion,
      openQuestionID,
      language,
      selectedPracticeArea,
      selectedRange,
    } = this.state;
    let langList = _.uniqBy(response, "Language");
    let practiceAreaList = _.uniqBy(response, "Area");
    let filteredData = response;
    if (language !== "all" || selectedPracticeArea !== "all") {
      filteredData = _.filter(response, (data) => {
        return (
          (language !== "all" ? data.Language == language : true) &&
          (selectedPracticeArea !== "all"
            ? data.Area == selectedPracticeArea
            : true)
        );
      });
    }
    return (
      <>
        <div className="near-by-question">
          <div className="filter-wrapper">
            <div className="form-group select-range">
              <label>Range</label>
              <select
                className="form-control form-control-lg"
                value={selectedRange}
                onChange={this.handleRangeChange}
              >
                <option value="zip">Same Zip Code</option>
                <option value="city">Same City</option>
                <option value="state">Same State</option>
              </select>
            </div>
            <div className="first-line">
              <div className="form-group select-language">
                <label>Language</label>
                <select
                  className="form-control form-control-lg"
                  value={language}
                  onChange={this.handleLanguageChange}
                >
                  <option value="all">All</option>
                  {langList.map((data: any) => (
                    <option value={data.Language}>
                      {getLanguageString(data.Language)}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group practice-area">
                <label>Practice Area</label>
                <select
                  className="form-control form-control-lg"
                  value={selectedPracticeArea}
                  onChange={this.handleChangePracticeArea}
                >
                  <option value="all">All</option>
                  {practiceAreaList.map((data: any) => (
                    <option value={data.Area}>{data.Area}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <hr></hr>
          {response.length !== 0 && (
            <>
              <div className="question-list-wrapper">
                {filteredData.map((data: any, index: number) => (
                  <div className="question-item border rounded p-2 pl-3 pr-3 position-relative">
                    <div className="detail-wrapper">
                      <h3 className="question">
                        {data.Question}
                        <span className="language">
                          {" [" + getLanguageString(data.Language) + "]"}
                        </span>
                      </h3>
                      <h5>{data.Area}</h5>
                    </div>
                    <button
                      className="btn btn-primary btn-add-ans"
                      onClick={() => this.openQuestionModal(data.ID)}
                    >
                      Add Answer
                    </button>
                  </div>
                ))}
              </div>
              {filteredData.length === 0 && (
                <div className="empty-view-wrapper">
                  <div className="empty-view">
                    <div>
                      No questions found for the selected filter options
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {response.length === 0 && (
            <div className="empty-view-wrapper">
              <div className="empty-view">
                <div>
                  No nearby user asked any questions yet. This is where you'll
                  see a list of questions asked by the nearest users
                </div>
              </div>
            </div>
          )}
        </div>
        {openQuestion && (
          <QuestionModal
            openModal={openQuestion}
            QueID={openQuestionID}
            closeCallBack={this.closeQuestionModal}
            refreshList={this.getQuestionList}
          ></QuestionModal>
        )}
      </>
    );
  }
}

export default withRouter(NearbyQuestion);
