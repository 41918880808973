import React from "react";
import Axios from "axios";
import _ from "lodash";
import { APIUrl } from "../../Config/ApiConfig";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Path, ActionType } from "../../constants";
import "./style.css";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import Footer from "../Footer";

interface Props extends WithTranslation {
  history: History;
  document?: any;
  dispatch: any;
  language: any;
}
interface State {
  allQuestionsResponse: any;
  predictedQue: any;
  errors: any;
  searchQue: string;
  selectedQue: any;
  zipCode: any;
  showLogInOption?: boolean;
  showLanOption: boolean;
}

class Home extends React.Component<Props & RouteComponentProps<{}>, State> {
  constructor(props: Props & RouteComponentProps<{}>) {
    super(props);
    this.state = {
      allQuestionsResponse: [],
      predictedQue: [],
      searchQue: "",
      errors: { practice: "", searchQue: "" },
      selectedQue: {},
      zipCode: "",
      showLogInOption: false,
      showLanOption: false,
    };
  }
  componentDidMount() {
    this.getAllQuestionsResponse();
    window.onclick = (event: any) => {
      if (!event.target.matches(".dropbtn")) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains("show")) {
            openDropdown.classList.remove("show");
            this.setState({ showLogInOption: false, showLanOption: false });
          }
        }
      }
    };
  }
  gotoLogin = (e: any) => {
    this.props.history.push(Path.LawyerLogin);
  };
  gotoRegister = (e: any) => {
    this.props.history.push(Path.SignUp);
  };

  getAllQuestionsResponse = async () => {
    let response = await Axios.get(
      APIUrl + "/Public/GetAllQuestionsInSequence"
    );
    this.setState({ allQuestionsResponse: response.data });
  };
  handleSearchChange = (search: string) => {
    const { allQuestionsResponse } = this.state;
    this.setState({ searchQue: search });
    let result: any = [];
    let predictedQue: any = [];
    if (search) {
      let searchArr = search.split(" ");

      _.forEach(searchArr, (data: any) => {
        if (data) {
          predictedQue.push(
            _.filter(allQuestionsResponse, (queData) => {
              return queData.Question.toLowerCase().includes(
                data.toLowerCase()
              );
            })
          );
        }
      });
      _.forEach(predictedQue, (data: any) => {
        if (data) {
          _.forEach(data, (qData: any) => {
            let index = _.findIndex(result, { ID: qData.ID });
            if (index > -1) {
              result[index] = { count: result[index].count + 1, ...qData };
            } else {
              result.push({ count: 1, ...qData });
            }
          });
        }
      });
    } else {
      this.setState({ predictedQue: [] });
    }
    this.setState({ predictedQue: _.orderBy(result, ["count"], ["desc"]) });
  };

  handleAreaClick = (search: string) => {
    let ID = this.handleAreaSelect(search);
    const { history } = this.props;
    let { zipCode } = this.state;
    if (!zipCode) {
      zipCode = 0;
    }
    if (ID > 0) {
      history && history.push(Path.showAns + "/" + zipCode + "/" + ID);
    }
  };

  handleAreaSelect = (search: string) => {
    const { allQuestionsResponse } = this.state;
    if (allQuestionsResponse.length > 0) {
      let area: any = [];
      let match: any = [];
      let result: any = [];
      _.forEach(allQuestionsResponse, (data) => {
        if (area.indexOf(data.Area) === -1) {
          area.push(data.Area);
        }
      });
      let searchArr = search.split(" ");

      _.forEach(searchArr, (data: any) => {
        if (data) {
          match.push(
            _.filter(area, (queData) => {
              return queData.toLowerCase().includes(data.toLowerCase());
            })
          );
        }
      });
      _.forEach(match, (data: any) => {
        if (data) {
          _.forEach(data, (qData: any) => {
            let index = _.findIndex(result, { qData: qData });
            if (index > -1) {
              result[index] = { count: result[index].count + 1, qData };
            } else {
              result.push({ count: 1, qData });
            }
          });
        }
      });
      let finalResult = _.orderBy(result, ["count"], ["desc"]);
      if (finalResult.length > 0) {
        let finalMetch = _.filter(allQuestionsResponse, (data: any) => {
          return data.Area === finalResult[0].qData;
        });
        if (finalMetch.length > 0) return finalMetch[0].ID;
      }
      return allQuestionsResponse[0].ID;
    }
    return 0;
  };

  highlight = (value: any) => {
    value = "#**#" + value + "#**#";
    const { searchQue } = this.state;
    let searchArr = searchQue.split(" ");
    let newValue: any = value;
    _.forEach(searchArr, (data: any) => {
      if (data) {
        var re;
        if (data === "?") re = "?";
        else re = new RegExp(data, "i");
        let arr = newValue.split(re);
        if (arr.length > 1) {
          newValue = arr[0];
          for (let i = 1; i < arr.length; i++) {
            newValue += "<u>" + data + "</u>" + arr[i];
          }
        }
      }
    });
    return newValue.replace("#**#", "").replace("#**#", "");
  };
  selectQue = (selectedQue: any) => {
    this.setState({
      selectedQue: selectedQue,
      searchQue: selectedQue.Question,
      predictedQue: [],
    });
  };
  searchVideoQue = () => {
    const { history, t } = this.props;
    const { selectedQue } = this.state;
    let { zipCode } = this.state;
    if (!zipCode) {
      zipCode = 0;
    }
    if (selectedQue.ID) {
      history &&
        history.push(Path.showAns + "/" + zipCode + "/" + selectedQue.ID);
    } else {
      alert(t("messages.selectFromSuggestions"));
    }
  };

  showHide = () => {
    this.setState({ showLogInOption: !this.state.showLogInOption });
  };
  showHideLan = () => {
    this.setState({ showLanOption: !this.state.showLanOption });
  };

  render() {
    const {
      predictedQue,
      searchQue,
      zipCode,
      showLogInOption,
      showLanOption,
    } = this.state;
    const { history, t, language, dispatch } = this.props;

    return (
      <>
        <Helmet>
          <title>golawgo - Home</title>
        </Helmet>
        <div className="home">
          <main className="site-main">
            <div
              className={
                "sec-home-banner " +
                (searchQue.length > 0 && predictedQue.length > 0
                  ? "overflow-unset"
                  : "")
              }
              style={{ backgroundImage: "url(img/home-banner.jpg)" }}
            >
              <div className="container">
                <div className="topOptions">
                  <div className="div-landing-lan">
                    <div className="dropdown">
                      <button
                        onClick={this.showHideLan}
                        className="btn btn-primary btn-landing-lan dropbtn"
                      >
                        {language === "en" && "English"}
                        {language === "es" && "Spanish"}
                        {language === "zh" && "Mandarin"}
                        {language === "vi" && "Vietnamese"}
                      </button>
                      <div
                        className={
                          "dropdown-content" + (showLanOption ? " show" : "")
                        }
                      >
                        {language !== "en" && (
                          <a
                            href="javascript:void(0)"
                            onClick={() => {
                              dispatch({
                                type: ActionType.SetLanguage,
                                payload: "en",
                              });
                              window.location.reload();
                            }}
                          >
                            English
                          </a>
                        )}
                        {language !== "es" && (
                          <a
                            href="javascript:void(0)"
                            onClick={() =>
                              dispatch({
                                type: ActionType.SetLanguage,
                                payload: "es",
                              })
                            }
                          >
                            Spanish
                          </a>
                        )}
                        {language !== "zh" && (
                          <a
                            href="javascript:void(0)"
                            onClick={() =>
                              dispatch({
                                type: ActionType.SetLanguage,
                                payload: "zh",
                              })
                            }
                          >
                            Mandarin
                          </a>
                        )}
                        {language !== "vi" && (
                          <a
                            href="javascript:void(0)"
                            onClick={() =>
                              dispatch({
                                type: ActionType.SetLanguage,
                                payload: "vi",
                              })
                            }
                          >
                            Vietnamese
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="div-landing-login">
                    <div className="dropdown">
                      <button
                        onClick={this.showHide}
                        className="btn btn-primary btn-landing-login dropbtn"
                      >
                        {t("buttons.signIn")}
                      </button>
                      <div
                        className={
                          "dropdown-content" + (showLogInOption ? " show" : "")
                        }
                      >
                        <a
                          href="javascript:void(0)"
                          onClick={() => history.push(Path.UserLogin)}
                        >
                          {t("buttons.userSignIn")}
                        </a>
                        <a
                          href="javascript:void(0)"
                          onClick={() => history.push(Path.LawyerLogin)}
                        >
                          {t("buttons.attorneySignIn")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row home-banner-text">
                  <div className="col-12 top-logo mb-5">
                    <h1 className="text-center">golawgo</h1>
                  </div>
                  <div className="col-lg-12">
                    <div className="row text-center mt-5">
                      <div className="col-12 mb-4">
                        <h3>{t("landing.header.title1")}</h3>
                      </div>
                      <div className="col-lg-8 col-md-7 search-bar">
                        <i className="fa fa-search icon"></i>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t(
                            "landing.inputPlaceholder.typeQuestion"
                          )}
                          value={searchQue}
                          onChange={(e: any) =>
                            this.handleSearchChange(e.target.value)
                          }
                          // onBlur={() => this.setState({ predictedQue: [] })}
                          onFocus={(e: any) =>
                            this.handleSearchChange(e.target.value)
                          }
                        ></input>

                        <div
                          className={
                            "text-left predicted-questions " +
                            (predictedQue.length > 0 ? "" : "hide")
                          }
                        >
                          <div>
                            {predictedQue &&
                              predictedQue.map((data: any, index: number) => (
                                <div
                                  key={index}
                                  onClick={() => this.selectQue(data)}
                                  dangerouslySetInnerHTML={{
                                    __html: this.highlight(data.Question),
                                  }}
                                />
                              ))}
                          </div>
                        </div>
                        {predictedQue && searchQue.length > 0 && (
                          <div
                            className="back-cover"
                            onClick={() => this.setState({ predictedQue: [] })}
                          ></div>
                        )}
                      </div>
                      <div className="col-lg-2 col-md-3 zip-bar">
                        <i className="fa fa-map-marker icon"></i>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("landing.inputPlaceholder.zipCode")}
                          value={zipCode}
                          onChange={(e: any) =>
                            this.setState({ zipCode: e.target.value })
                          }
                        ></input>
                      </div>
                      <div className="col-2 btn-go">
                        <button
                          className="btn btn-success w-100 h-100"
                          type="button"
                          onClick={this.searchVideoQue}
                        >
                          {t("buttons.go")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="videos-by-topic">
              <div className="container">
                <div className="row">
                  <div className="col-12 mb-5">
                    <h3 className="text-center">
                      {t("landing.header.title2")}
                    </h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-2 col-sm-6">
                    <div
                      className="law-div text-center"
                      onClick={() => this.handleAreaClick("Family law")}
                    >
                      <div className="law-img">
                        <img alt="family" src="img/family.svg"></img>
                      </div>
                      <a className="text-uppercase" href="javascript:void(0)">
                        {t("landing.areaCards.family")}
                      </a>
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-6">
                    <div
                      className="law-div text-center"
                      onClick={() =>
                        this.handleAreaClick("Personal Injury Law")
                      }
                    >
                      <div className="law-img">
                        <img alt="law" src="img/994414.svg"></img>
                      </div>
                      <a className="text-uppercase" href="javascript:void(0)">
                        {t("landing.areaCards.personal")}
                      </a>
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-6">
                    <div
                      className="law-div text-center"
                      onClick={() => this.handleAreaClick("Criminal Law")}
                    >
                      <div className="law-img">
                        <img alt="law" src="img/jail.svg"></img>
                      </div>
                      <a className="text-uppercase" href="javascript:void(0)">
                        {t("landing.areaCards.criminal")}
                      </a>
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-6">
                    <div
                      className="law-div text-center"
                      onClick={() => this.handleAreaClick("Bankruptcy")}
                    >
                      <div className="law-img">
                        <img alt="law" src="img/134655.svg"></img>
                      </div>
                      <a className="text-uppercase" href="javascript:void(0)">
                        {t("landing.areaCards.bankruptcy")}
                      </a>
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-6">
                    <div
                      className="law-div text-center"
                      onClick={() => this.handleAreaClick("Tax Law")}
                    >
                      <div className="law-img">
                        <img alt="law" src="img/2829919.svg"></img>
                      </div>
                      <a className="text-uppercase" href="javascript:void(0)">
                        {t("landing.areaCards.tax")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="section popular-questions">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 title-div text-center">
                    <span className="d-block w-100 text-uppercase text-blue mb-3">
                      {t("landing.keywords.DISCOVER")}
                    </span>
                    <h2 className="mb-3"> {t("landing.header.title3")}</h2>
                    <span className="d-inline-block title-line"></span>
                  </div>
                </div>
                <div className="row pt-5">
                  <div className="col-lg-4 col-md-12">
                    <div
                      className="question-div"
                      style={{ backgroundImage: "url(img/law.jpg)" }}
                    >
                      <div className="tags-law">
                        <span className="text-uppercase">
                          {t("landing.areaCards.DWI")}
                        </span>
                      </div>
                      <div className="question-aws mt-5">
                        <div className="aws">
                          <div className="aws-img"></div>
                          12 {t("landing.keywords.answers")}
                        </div>
                        <div className="qut">
                          {t("landing.questionCard.que1")}
                        </div>
                        <div className="lcn">
                          <i className="fa fa-map-marker icon"></i>
                          {t("landing.questionCard.address1")}
                        </div>
                      </div>
                      <div className="like">
                        <a className="active" href="javascript:void(0)">
                          <img className="hr1" alt="hr1" src="img/h1.svg"></img>
                          <img className="hr2" alt="hr2" src="img/h2.svg"></img>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div
                      className="question-div"
                      style={{ backgroundImage: "url(img/law.jpg)" }}
                    >
                      <div className="tags-law">
                        <span className="text-uppercase">
                          {t("landing.areaCards.family")}
                        </span>
                      </div>
                      <div className="question-aws mt-5">
                        <div className="aws">
                          <div className="aws-img"></div>
                          10 {t("landing.keywords.answers")}
                        </div>
                        <div className="qut">
                          {t("landing.areaCards.family")}
                        </div>
                        <div className="lcn">
                          <i className="fa fa-map-marker icon"></i>
                          {t("landing.questionCard.address2")}
                        </div>
                      </div>
                      <div className="like">
                        <a className="" href="javascript:void(0)">
                          <img className="hr1" alt="hr1" src="img/h1.svg"></img>
                          <img className="hr2" alt="hr2" src="img/h2.svg"></img>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div
                      className="question-div"
                      style={{ backgroundImage: "url(img/law.jpg)" }}
                    >
                      <div className="tags-law">
                        <span className="text-uppercase">
                          {t("landing.areaCards.bankruptcy")}
                        </span>
                      </div>
                      <div className="question-aws mt-5">
                        <div className="aws">
                          <div className="aws-img"></div>
                          12 {t("landing.keywords.answers")}
                        </div>
                        <div className="qut">
                          {t("landing.questionCard.que2")}
                        </div>
                        <div className="lcn">
                          <i className="fa fa-map-marker icon"></i>
                          {t("landing.questionCard.address1")}
                        </div>
                      </div>
                      <div className="like">
                        <a className="" href="javascript:void(0)">
                          <img className="hr1" alt="#h1" src="img/h1.svg"></img>
                          <img className="hr2" alt="#h2" src="img/h2.svg"></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 title-div text-center mt-5 color-p">
                    <span className="d-block w-100 text-uppercase text-blue mb-3">
                      {t("landing.keywords.or")}
                    </span>
                    <h2
                      className="mb-3 color-p clickable"
                      onClick={() => history.push(Path.HubSpokeQuestionList)}
                    >
                      {t("landing.header.title4")}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="section how-its-work"
              style={{ backgroundImage: "url(img/tex.jpg)" }}
            >
              <div className="container">
                <div className="row pb-4">
                  <div className="col-12 title-div text-center">
                    <span className="d-block w-100 text-black mb-5 pb-4">
                      {t("landing.header.title5")}
                    </span>
                    <h2 className="mb-3">{t("landing.header.title6")}</h2>
                    <span className="d-inline-block title-line"></span>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="loading-bar"></div>
                  <div className="col-md-4">
                    <div className="how-div text-center">
                      <div className="how-img">
                        <img src="img/disable-cursor.svg" alt="how-img"></img>
                        <div className="num">1</div>
                      </div>
                      <div className="how-text">
                        <h3 className="text-black">
                          {t("landing.questionAnswer.q1")}
                        </h3>
                        <p>{t("landing.questionAnswer.a1")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="how-div text-center">
                      <div className="how-img">
                        <img src="img/movie.svg" alt="how-img"></img>
                        <div className="num">2</div>
                      </div>
                      <div className="how-text">
                        <h3 className="text-black">
                          {t("landing.questionAnswer.q2")}
                        </h3>
                        <p>{t("landing.questionAnswer.a2")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="how-div text-center">
                      <div className="how-img">
                        <img
                          alt="how-img"
                          src="img/partnership-handshake.svg"
                        ></img>
                        <div className="num">3</div>
                      </div>
                      <div className="how-text">
                        <h3 className="text-black">
                          {t("landing.questionAnswer.q3")}
                        </h3>
                        <p>{t("landing.questionAnswer.a3")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="section watch-video"
              style={{ backgroundImage: "url(img/watch.jpg)" }}
            >
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 title-div text-center">
                    <span className="d-block w-100 text-uppercase text-blue mb-4">
                      {t("landing.watchVideo.title")}
                    </span>
                    <h2 className="mb-3">
                      {t("landing.watchVideo.question")} <br></br>
                      {t("landing.watchVideo.answer")}
                    </h2>
                    <span className="d-inline-block title-line"></span>
                  </div>
                  <div className="col-12 pt-5 mt-4">
                    <div className="play-btn text-center">
                      <a
                        id="play-video"
                        className="video-play-button"
                        href="javascript:void(0)"
                      >
                        <span></span>
                      </a>
                      <div id="video-overlay" className="video-overlay">
                        <a
                          className="video-overlay-close"
                          href="javascript:void(0)"
                        >
                          &times;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section feedback-sec">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="feedback-slider">
                      <div>
                        <h4 className="text-black">
                          "{t("landing.feedback.f1")}"
                        </h4>
                        <div className="feedimg">
                          <img
                            className="arrow-img"
                            alt="arrow"
                            src="img/arrow.png"
                          ></img>
                          <img
                            className="user-img"
                            alt="tm01"
                            src="img/tm01.jpg"
                          ></img>
                          <h5>
                            {t("landing.feedback.n1")}
                            <span>{t("landing.feedback.l1")}</span>
                          </h5>
                        </div>
                      </div>
                      <div>
                        <h4 className="text-black">
                          "{t("landing.feedback.f2")}"
                        </h4>
                        <div className="feedimg">
                          <img
                            className="arrow-img"
                            alt="arrow"
                            src="img/arrow.png"
                          ></img>
                          <img
                            className="user-img"
                            src="img/tm02.jpg"
                            alt="tm02"
                          ></img>
                          <h5>
                            {t("landing.feedback.n2")}
                            <span>{t("landing.feedback.l2")}</span>
                          </h5>
                        </div>
                      </div>
                      <div>
                        <h4 className="text-black">
                          "{t("landing.feedback.f3")}"
                        </h4>
                        <div className="feedimg">
                          <img
                            className="arrow-img"
                            alt="arrow"
                            src="img/arrow.png"
                          ></img>
                          <img
                            className="user-img"
                            alt="tm03"
                            src="img/tm03.jpg"
                          ></img>
                          <h5>
                            {t("landing.feedback.n3")}
                            <span>{t("landing.feedback.l3")}</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer></Footer>
          <a href="javascript:void(0)" id="scroll" style={{ display: "none" }}>
            <span></span>
          </a>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state: any) => ({
  language: state.language.type,
});

export default connect(
  mapStateToProps,
  null
)(withTranslation()(withRouter(Home)));
