import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";

interface Props extends WithTranslation {}

class Footer extends React.Component<Props> {
  render() {
    const { t } = this.props;
    return (
      <div className="home">
        <footer className="site-footer ">
          <div className="container">
            <div className="row">
              <div className="col-12 footer-top">
                <div className="row">
                  <div className="col-md-4">
                    <div className="ft-text">
                      <h3>{t("footer.about.title")}</h3>
                      <ul>
                        <li className="sub-title">
                          {t("footer.about.description")}
                        </li>
                        {/* <li className="icon-link">
                          <span>
                            <i className="fa fa-map-marker"></i>
                          </span>
                          Wonder Street, USA, New York
                        </li>
                        <li className="icon-link">
                          <span>
                            <i className="fa fa-phone"></i>
                          </span>
                          <a href="tel:+01321654214">+01 321 654 214</a>
                        </li>
                        <li className="icon-link">
                          <span>
                            <i className="fa fa-envelope"></i>
                          </span>
                          <a href="mailto:hello@gmail.com">hello@gmail.com</a>
                        </li> */}
                        <li className="soc-link">
                          <a href="javascript:void(0)">
                            <i className="fa fa-facebook"></i>
                          </a>
                          <a href="javascript:void(0)">
                            <i className="fa fa-twitter"></i>
                          </a>
                          <a href="javascript:void(0)">
                            <i className="fa fa-instagram"></i>
                          </a>
                          <a href="javascript:void(0)">
                            <i className="fa fa-linkedin"></i>
                          </a>
                          <a href="javascript:void(0)">
                            <i className="fa fa-pinterest-p"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="ft-text">
                      <h3>{t("footer.quick.title")}</h3>
                      <ul>
                        <li>
                          <a href="javascript:void(0)">
                            {t("footer.quick.l1")}
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            {t("footer.quick.l2")}
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            {t("footer.quick.l3")}
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            {t("footer.quick.l4")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="ft-text">
                      <h3>{t("footer.quick.title")}</h3>
                      <ul>
                        <li className="sub-title">
                          {t("footer.quick.description")}
                        </li>
                        <li className="subs-form">
                          <span>{t("footer.quick.inputTitle")}</span>
                          <input
                            type="Email"
                            placeholder={t("footer.quick.inputPlaceholder")}
                          ></input>
                          <button className="submit">
                            {t("buttons.subscribe")}
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 footer-bottom">
                <div>
                  {t("footer.copyRight.start")}{" "}
                  <i className="fa fa-copyright"></i>
                  2020 <strong style={{ color: "#fff" }}>golawgo</strong> |{" "}
                  {t("footer.copyRight.end")}
                </div>
              </div>
            </div>
          </div>
        </footer>
        <script src="js/jquery.min.js"></script>
        <script src="js/popper.min.js"></script>
        <script src="js/bootstrap.min.js"></script>
        <script src="js/slick.min.js"></script>
      </div>
    );
  }
}
export default withTranslation()(Footer);
