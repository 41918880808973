import React from "react";
import "./style.css";

interface Props {
  play: boolean;
  url: string;
  applyMinHeight?: boolean;
}
interface State {
  isPlaying: boolean;
}

class VideoPlayer extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { isPlaying: false };
  }

  componentDidUpdate() {
    const { isPlaying } = this.state;
    const { play } = this.props;
    if (isPlaying !== play) {
      if (play) this.videoRef.current.play();
      else this.videoRef.current.pause();
      this.setState({ isPlaying: play });
    }
  }

  componentDidMount() {
    const { play } = this.props;
    if (play) this.videoRef.current.play();
    else this.videoRef.current.pause();
  }

  onClick = () => {
    const { isPlaying } = this.state;
    if (isPlaying) {
      this.videoRef.current.pause();
    } else {
      this.videoRef.current.play();
    }
    this.setState({ isPlaying: !isPlaying });
  };

  videoRef = React.createRef<any>();

  render() {
    const { url, play, applyMinHeight = false } = this.props;
    if (play)
      this.videoRef && this.videoRef.current && this.videoRef.current.play();
    else
      this.videoRef && this.videoRef.current && this.videoRef.current.pause();
    return (
      <div className={applyMinHeight ? "video-player-wapper" : ""}>
        <video
          className="video-player"
          onClick={this.onClick}
          loop
          src={url}
          controls={false}
          ref={this.videoRef}
          playsInline
        ></video>
      </div>
    );
  }
}

export default VideoPlayer;
