import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { APIUrl } from "Config/ApiConfig";
import _ from "lodash";
import { Path, MaxFileSize } from "./../../../../constants";
import Axios from "axios";
import { toast } from "utils/toast";
import { getLanguageString } from "utils/common";
import { Grid, Button, Divider } from "@material-ui/core";
import VideoPlayer from "Components/VideoPlayer/VideoPlayer";
import CopyToClipboard from "react-copy-to-clipboard";
import YouTubePlayer from "Components/VideoPlayer/YouTubePlayer";

interface Props {
  openModal?: boolean;
  Token?: string;
  history?: History;
  Auth?: any;
  QueID: number;
  closeCallBack?: any;
  refreshList?: any;
}
interface State {
  QueModal: any;
  InitQueModal: any;
  copied: boolean;
  playAnsVideo: boolean;
  answerLink: string;
}

class QuestionModal extends React.Component<
  Props & RouteComponentProps<{}>,
  State
> {
  constructor(props: Props & RouteComponentProps<{}>) {
    super(props);
    this.state = {
      InitQueModal: {
        QueId: 0,
        AnsId: 0,
        Question: "",
        Answer: "",
        VideoType: 0,
        errors: { Answer: "" },
        IsPublish: true,
        IsDeleted: false,
      },
      QueModal: {
        QueId: 0,
        AnsId: 0,
        Question: "",
        Answer: "",
        VideoType: 0,
        errors: { Answer: "" },
        IsPublish: true,
        IsDeleted: false,
      },
      copied: false,
      playAnsVideo: false,
      answerLink: "",
    };
  }

  componentDidMount() {
    this.getQuestion();
  }

  getQuestion = async () => {
    const { QueID } = this.props;
    let modalData = _.cloneDeep(this.state.QueModal);
    let response = await Axios.get(
      APIUrl +
        "/LawyerProfile/GetQuestionsForUser?UserID=" +
        this.props.Auth.UserID +
        "&QueID=" +
        QueID
    );
    if (response.data.answer) {
      modalData.Answer = _.get(response.data, "answer.VideoLink", "");
      modalData.VideoType = _.get(response.data, "answer.VideoType", 0);
      if (modalData.VideoType !== 0)
        this.setState({ answerLink: modalData.Answer });
      else this.setState({ answerLink: "" });
      modalData.AnsId = _.get(response.data, "answer.ID", 0);
      modalData.IsPublish = _.get(response.data, "answer.IsPublish", false);
      modalData.IsDeleted = _.get(response.data, "answer.IsDeleted", false);
    }
    if (response.data.questionMaster) {
      modalData.Language = _.get(response.data, "questionMaster.Language", "");
      modalData.QueId = _.get(response.data, "questionMaster.ID", 0);
      modalData.Question = _.get(response.data, "questionMaster.Question", "");
    }
    this.setState({ QueModal: modalData });
  };

  handleFileUpload = async (files: any) => {
    const { QueModal } = this.state;
    const { refreshList } = this.props;
    if (files.length > 0) {
      if (files[0].size > MaxFileSize.size) {
        toast.error({
          message: MaxFileSize.message,
          timeout: 15000,
        });
        return;
      }
      const formData = new FormData();
      formData.append("video", files[0]);
      formData.append(
        "QueModal",
        JSON.stringify({
          ...QueModal,
          UserID: this.props.Auth.UserID,
        })
      );
      this.setState({ playAnsVideo: false });
      var res = await Axios.post(APIUrl + "/LawyerProfile/SaveQue", formData);
      this.setState({
        QueModal: { ...QueModal, errors: { Answer: "" } },
        playAnsVideo: true,
      });
      refreshList && refreshList();
      this.getQuestion();
    }
  };

  saveLinkAns = async () => {
    const { QueModal, answerLink } = this.state;
    const { refreshList } = this.props;
    this.setState({ playAnsVideo: false });
    var res = await Axios.post(APIUrl + "/LawyerProfile/SaveLinkQue", {
      Answer: answerLink,
      QueId: QueModal.QueId,
      UserID: this.props.Auth.UserID,
      AnsId: QueModal.AnsId,
    });
    this.setState({
      QueModal: { ...QueModal, errors: { Answer: "" } },
      playAnsVideo: true,
    });
    refreshList && refreshList();
    this.getQuestion();
  };

  handlePublish = async (ansId: number) => {
    const { QueModal } = this.state;
    let response = await Axios.post(APIUrl + "/LawyerProfile/Publish/", {
      ...QueModal,
      UserID: this.props.Auth.UserID,
    });
    let modalData = _.cloneDeep(this.state.QueModal);
    if (response.data.answer) {
      // modalData.Answer = _.get(response.data, "answer.VideoLink", "");
      // modalData.AnsId = _.get(response.data, "answer.ID", 0);
      modalData.IsPublish = _.get(response.data, "answer.IsPublish", false);
      // modalData.IsDeleted = _.get(response.data, "answer.IsDeleted", false);
    }
    // if (response.data.questionMaster) {
    //   modalData.QueId = _.get(response.data, "questionMaster.ID", 0);
    //   modalData.Question = _.get(response.data, "questionMaster.Question", "");
    // }
    this.setState({ QueModal: modalData });
  };

  handleUnPublish = async (ansId: number) => {
    const { QueModal } = this.state;
    let response = await Axios.post(APIUrl + "/LawyerProfile/Unpublish/", {
      ...QueModal,
      UserID: this.props.Auth.UserID,
    });
    let modalData = _.cloneDeep(this.state.QueModal);
    if (response.data.answer) {
      // modalData.Answer = _.get(response.data, "answer.VideoLink", "");
      // modalData.AnsId = _.get(response.data, "answer.ID", 0);
      modalData.IsPublish = _.get(response.data, "answer.IsPublish", false);
      // modalData.IsDeleted = _.get(response.data, "answer.IsDeleted", false);
    }
    // if (response.data.questionMaster) {
    //   modalData.QueId = _.get(response.data, "questionMaster.ID", 0);
    //   modalData.Question = _.get(response.data, "questionMaster.Question", "");
    // }
    this.setState({ QueModal: modalData });
  };

  handleAnsDelete = async () => {
    const { QueModal } = this.state;
    const { refreshList, closeCallBack } = this.props;
    await Axios.post(APIUrl + "/LawyerProfile/RemoveAns/", {
      ...QueModal,
      UserID: this.props.Auth.UserID,
    });
    refreshList && refreshList();
    closeCallBack && closeCallBack();
  };

  copyLink = (e: any) => {
    const { QueModal } = this.state;
    let domain = window.location.href
      .replace("http://", "")
      .replace("https://", "")
      .split("/")[0];
    let tempInput: any = document.createElement("input");
    tempInput.value = domain + Path.FindPublicAnswer + "/" + QueModal.AnsId;
    document.body.appendChild(tempInput);
    tempInput.select();
    // tempInput.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.body.removeChild(tempInput);
  };

  render() {
    let domain = window.location.href
      .replace("http://", "")
      .replace("https://", "")
      .split("/")[0];
    const { QueModal, playAnsVideo, copied, answerLink } = this.state;
    const { openModal = false, closeCallBack } = this.props;
    console.log(openModal === true && playAnsVideo + " ||| " + QueModal.Answer);
    return (
      <div>
        <Modal
          show={openModal}
          className="AdminQuestionModal"
          onHide={closeCallBack}
        >
          <Modal.Header closeButton>
            {QueModal &&
              QueModal.Question +
                " [" +
                getLanguageString(QueModal.Language) +
                "]"}
          </Modal.Header>
          <Modal.Body>
            {QueModal && QueModal.Question && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    {QueModal.AnsId > 0 && (
                      <div
                        onClick={() =>
                          this.setState({ playAnsVideo: !playAnsVideo })
                        }
                      >
                        {QueModal.Answer && QueModal.VideoType === 0 && (
                          <VideoPlayer
                            play={openModal === true && playAnsVideo}
                            url={"" + QueModal.Answer}
                          ></VideoPlayer>
                        )}
                        {QueModal.Answer && QueModal.VideoType === 1 && (
                          <YouTubePlayer
                            play={openModal === true && playAnsVideo}
                            url={"" + QueModal.Answer}
                          ></YouTubePlayer>
                        )}
                      </div>
                    )}
                    {QueModal.AnsId > 0 && (
                      <CopyToClipboard
                        text={
                          domain + Path.FindPublicAnswer + "/" + QueModal.AnsId
                        }
                        onCopy={() => this.setState({ copied: true })}
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          component="label"
                          onClick={this.copyLink}
                          className="mt-3"
                        >
                          <span>{copied ? "Copied" : "Copy Answer Link"}</span>
                        </Button>
                      </CopyToClipboard>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      component="label"
                    >
                      <input
                        id="file-input"
                        type="file"
                        multiple={false}
                        accept={
                          ".MOV, .MP4, .MPEG4, .AVI, .WMV, .FLV, .3GPP, .WebM"
                        }
                        style={{ display: "none" }}
                        onChange={(e: any) => {
                          if (e.target.files && e.target.files.length > 0) {
                            this.handleFileUpload(e.target.files);
                          }
                        }}
                      ></input>
                      {QueModal.AnsId > 0 ? "Upload New Video" : "Upload Video"}
                    </Button>

                    <div className="form-group text-left mt-2 or-youtube-link">
                      <label>
                        <b>Or Add Your YouTube Video Link</b>
                      </label>
                      <span className="position-relative">
                        <input
                          type="text"
                          name="password"
                          placeholder="Add Your YouTube Video Link"
                          value={answerLink}
                          onChange={(e) =>
                            this.setState({ answerLink: e.target.value })
                          }
                        ></input>
                        <Button
                          variant="contained"
                          color="primary"
                          component="label"
                          onClick={() => this.saveLinkAns()}
                          className="btn"
                          disabled={answerLink.length === 0}
                        >
                          <span>save</span>
                        </Button>
                        {/* <button className="btn">save</button> */}
                      </span>
                    </div>
                  </Grid>
                </Grid>
                {QueModal.AnsId > 0 && (
                  <>
                    <Divider
                      variant="fullWidth"
                      style={{ margin: "15px 0px" }}
                    />
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        {QueModal && QueModal.IsPublish ? (
                          <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={() => this.handleUnPublish(QueModal.AnsId)}
                          >
                            Unpublish
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            variant="contained"
                            style={{
                              backgroundColor: "#288e2c",
                              color: "white",
                            }}
                            onClick={() => this.handlePublish(QueModal.AnsId)}
                          >
                            Publish
                          </Button>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Button
                          fullWidth
                          variant="contained"
                          style={{
                            backgroundColor: "#f44336",
                            color: "white",
                          }}
                          onClick={() => this.handleAnsDelete()}
                        >
                          Delete
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  Token: state.auth.Token,
  Auth: state.auth.Auth,
});

export default connect(mapStateToProps, null)(withRouter(QuestionModal));
