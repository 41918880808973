import { ActionType } from "../../constants";
import i18n from "i18next";

const initialState = {
  type: localStorage.getItem("langType")
    ? localStorage.getItem("langType")
    : "en",
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ActionType.SetLanguage:
      localStorage.setItem("langType", action.payload);
      window.location.reload();
      i18n.changeLanguage(action.payload);
      window.location.reload();
      return {
        ...state,
        type: action.payload,
      };
    default:
      return state;
  }
}
