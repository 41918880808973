import React from "react";
import {
  Typography,
  Theme,
  withStyles,
  createStyles,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import _ from "lodash";
import { APIUrl } from "../../Config/ApiConfig";
import { toast } from "../../utils/toast";
import { RegEx, Path } from "../../constants";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { History } from "history";
import Header from "../Header";
import Footer from "../Footer";
import "./style.css";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    wrapper: {
      position: "relative",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%",
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    buttonProgress: {
      color: "#fff",
    },
    txtBlue: {
      color: "#3f51b5",
    },
    txtRed: {
      color: "#f44336",
    },
  });

interface Props {
  classes?: any;
  match: any;
  history: History;
}
interface State {
  password: string;
  confirmPassword: string;
  errors: any;
  userEmail: string;
  showLoader: boolean;
  [key: string]: any;
  isLinkActive: boolean;
}
class PasswordSetUp extends React.Component<
  Props & RouteComponentProps<{}>,
  State
> {
  constructor(props: Props & RouteComponentProps<{}>) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
      userEmail: "",
      errors: { password: "", confirmPassword: "" },
      showLoader: false,
      isLinkActive: true,
    };
  }

  componentDidMount() {
    let has = _.get(this.props, "match.params.ID", "0");
    if (has !== "0") {
      axios
        .post(APIUrl + "/SignUp/PasswordSetUpVerification", {
          InviteCode: has,
        })
        .then((res) => {
          if (res.data.ResponseStatus === 0) {
            this.setState({ userEmail: res.data.attorneyUser.EmailID });
          } else {
            this.setState({ isLinkActive: false });
          }
        });
    }
  }

  gotoLogin = () => {
    this.props.history.push(Path.LawyerLogin);
  };

  handleOnChange = (e: any) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onBlur = (event: any) => {
    let keyName = event.target.name;
    let value = event.target.value;
    const passwordRegex = RegExp(RegEx.password);
    const { password, confirmPassword, errors } = this.state;
    const passwordFormat =
      "Password must be 8 characters long and contain 1 uppercase, 1 lowercase, 1 digit and 1 special character";
    const passwordNotMatch = "Password and confirm password not match";
    const pleaseEnterPassword = "Please enter Password";
    switch (keyName) {
      case "password":
        let passwordMessage = "";
        if (value.length) {
          if (!passwordRegex.test(value)) {
            passwordMessage = passwordFormat;
          }
        } else {
          passwordMessage = pleaseEnterPassword;
        }
        errors.password = passwordMessage;
        errors.confirmPassword =
          confirmPassword && confirmPassword.length && confirmPassword !== value
            ? passwordNotMatch
            : errors.confirmPassword;
        break;
      case "confirmPassword":
        let confirmPasswordMessage = "";
        if (value.length) {
          if (!passwordRegex.test(value)) {
            confirmPasswordMessage = passwordFormat;
          } else {
            if (password === value) {
              confirmPasswordMessage = "";
            } else if (password && password.length) {
              confirmPasswordMessage = passwordNotMatch;
            }
          }
        } else {
          confirmPasswordMessage = pleaseEnterPassword;
        }
        errors.confirmPassword = confirmPasswordMessage;
        break;
      default:
        break;
    }
    this.setState({ [keyName]: value, errors });
  };

  OnSubmit = async (e: any) => {
    const { password, confirmPassword, showLoader, errors } = this.state;
    const { history } = this.props;
    e.preventDefault();
    if (showLoader) return;
    this.setState({ showLoader: true });
    if (
      password.length &&
      confirmPassword.length &&
      !errors.password &&
      !errors.confirmPassword
    ) {
      let has = _.get(this.props, "match.params.ID", "0");
      let response = await axios.post(APIUrl + "/SignUp/PasswordSetUp", {
        InviteCode: has,
        Password: password,
      });
      if (response.data) {
        toast.destroy();
        if (response.data.ResponseStatus === 0) {
          history.push(Path.LawyerLogin);
        } else if (response.data.ResponseStatus === 404) {
          toast.error({
            message:
              "Something went wrong, please refresh the page and try again",
            timeout: 0,
          });
        }
      } else {
        toast.error({
          message: "Network error",
          timeout: 0,
        });
      }
    }
    this.setState({ showLoader: false });
  };

  render() {
    const { classes } = this.props;
    const {
      password,
      confirmPassword,
      showLoader,
      errors,
      userEmail,
      isLinkActive,
    } = this.state;
    return (
      <>
        <Header></Header>
        <div className="container">
          <div className="row login-container">
            <div className="col-md-3 col-sm-12"></div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="ft-text text-center">
                <h2 style={{ color: "black" }}>Password Setup</h2>
                {isLinkActive && (
                  <>
                    <div className="subs-form-1 text-black p-4">
                      <div className="form-group text-left mt-4">
                        <label>User ID</label>
                        <input
                          type="Email"
                          name="userEmail"
                          value={userEmail}
                        ></input>
                      </div>
                      <div className="form-group text-left">
                        <label>Password</label>
                        <input
                          type="password"
                          name="password"
                          placeholder="Enter your password"
                          onChange={this.handleOnChange}
                          onBlur={this.onBlur}
                          value={password}
                          autoFocus
                        ></input>
                        {errors && errors.password && (
                          <div className="error">
                            {errors && errors.password}
                          </div>
                        )}
                      </div>
                      <div className="form-group text-left">
                        <label>Confirm Password </label>
                        <input
                          placeholder="Enter confirm password"
                          type="password"
                          name="confirmPassword"
                          value={confirmPassword}
                          onChange={this.handleOnChange}
                          onBlur={this.onBlur}
                        ></input>
                        {errors && errors.confirmPassword && (
                          <div className="error">
                            {errors && errors.confirmPassword}
                          </div>
                        )}
                      </div>
                      <button className="submit" onClick={this.OnSubmit}>
                        {showLoader ? (
                          <span>
                            <CircularProgress
                              size={15}
                              className={classes.buttonProgress}
                            />
                            &nbsp; Please Wait
                          </span>
                        ) : (
                          "Submit"
                        )}
                      </button>
                      <div className="text-right goto-sign-up">
                        <a href="javascript:void(0)" onClick={this.gotoLogin}>
                          Already have an account? Sign in
                        </a>
                      </div>
                    </div>
                  </>
                )}
                {!isLinkActive && (
                  <Typography
                    component="h1"
                    variant="h6"
                    className="error text-center"
                  >
                    Link is already used!!!
                  </Typography>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </>
    );
  }
}

export default withStyles(styles)(withRouter(PasswordSetUp));
