import React from "react";
import "./Loader.css";

const Loader = () => {
  // const [ajaxCallCount, setAjaxCallCount] = useState(0);

  // const handleAxiosCallCount = (number) => {
  //   setAjaxCallCount((prevCount) => {
  //     return prevCount + number;
  //   });
  // };

  //   useEffect(() => {
  //     let reqInterceptor = null;
  //     let resInterceptor = null;
  //     reqInterceptor = axiosInstance.interceptors.request.use((request) => {
  //       handleAxiosCallCount(1);
  //       return request;
  //     });
  //     resInterceptor = axiosInstance.interceptors.response.use(
  //       (response) => {
  //         handleAxiosCallCount(-1);
  //         return response;
  //       },
  //       (error) => {
  //         handleAxiosCallCount(-1);
  //         return Promise.reject(error);
  //       }
  //     );
  //     return () => {
  //       axiosInstance.interceptors.request.eject(reqInterceptor);
  //       axiosInstance.interceptors.request.eject(resInterceptor);
  //     };
  //   }, []);

  //   if (ajaxCallCount === 0) {
  //     return null;
  //   }

  return <div className="Loader">Loader</div>;
};

export default Loader;
