import axios from "axios";
import store from "../redux/store";
import { ActionType, Path } from "../constants";
import { toast } from "./toast";
import { createBrowserHistory } from "history";

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  store.dispatch({
    type: ActionType.StartLoading,
    payload: {},
  });

  config.headers["AdminToken"] = localStorage.getItem("AdminToken");
  config.headers["UserToken"] = localStorage.getItem("UserToken");
  config.headers["AttorneyToken"] = localStorage.getItem("Token");
  config.headers["Language"] = localStorage.getItem("langType")
    ? localStorage.getItem("langType")
    : "en";
  return config;
});

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    store.dispatch({
      type: ActionType.StopLoading,
      payload: {},
    });
    return response;
  },
  function (error) {
    store.dispatch({
      type: ActionType.StopLoading,
      payload: {},
    });
    errorResponseHandler(error);
    return Promise.reject(error);
  }
);

function errorResponseHandler(error: any) {
  //Quasar Toast Schema
  let message = "Network error";

  //Setup Error Message
  if (typeof error !== "undefined") {
    if (error.hasOwnProperty("message")) {
      message = error.message;
    }
  }

  if (typeof error.response !== "undefined") {
    //Setup Generic Response Messages

    if (error.response.status === 401) {
      store.dispatch({
        type: ActionType.RemoveAdminToken,
        payload: {},
      });
      const history = createBrowserHistory();
      history && history.push(Path.Home);
      message = "Unauthorized Request, Please re-login to continue";
    } else if (error.response.status === 404) {
      message = "API route is missing or undefined";
    } else if (error.response.status === 405) {
      message = "API route method not allowed";
    } else if (error.response.status === 422) {
      //Validation Message
    } else if (error.response.status >= 500) {
      message = "Server error";
    }

    //Try to Use the Response Message
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      if (
        error.response.data.hasOwnProperty("message") &&
        error.response.data.message.length > 0
      ) {
        message = error.response.data.message;
      }
    }
  }

  //Toast the Message
  if (message.length > 0) {
    toast.error({
      message: message,
      timeout: 30000,
    });
  }
}
